<template>
  <div class="row m-0 mobile_menu p-0 ">
    <div class="col-9 px-0 py-3 menu_section menu_shadow top-env-padding">
      <div class="bg_overlay"></div>
      <div class="row m-0">
        <div class="col-12 px-3 text-end">
          <ios-close-icon class="inline-icon big cursor" @click="closeNav"/>
        </div>
      </div>
      <div class="row mx-0 mb-5">
        <div class="col-9 ps-5">
          <img :src="logo" class="img-fluid cursor logo" @click="goTo('')">
        </div>
      </div>
      <MenuItems @closeMenu="closeNav" />
      <div class="row mx-0 my-5 relative">
        <div class="col-10 ps-5 mb-2">
          <Button @buttonClicked="call('+441254698771')" color="goldGreen" width="100" size="menu" btnText="Call Our office" icon="arrow" v-if="isUK">
            <IconPhone color="green" size="size12"/>
          </Button>
          <Button @buttonClicked="call('+27363521557')" color="goldGreen" width="100" size="menu" btnText="Call Our office" icon="arrow" v-else>
            <IconPhone color="green" size="size12"/>
          </Button>
        </div>
        <!-- <div class="col-10 ps-5 mb-3">
          <Button color="goldGreen" width="100" btnText="Bank Donation" size="menu" icon="arrow">
            <IconBank color="green" size="size16"/>
          </Button>
        </div> -->
        <div class="col-10 ps-5">
          <div class="row m-0">
            <div class="col-auto px-1">
              <IconRound size="xsmall" align="center" @click="facebook" class="cursor">
                <logo-facebook-icon />
              </IconRound>
            </div>
            <div class="col-auto px-1">
              <IconRound size="xsmall" align="center" @click="twitter" class="cursor">
                <logo-twitter-icon />
              </IconRound>
            </div>
            <div class="col-auto px-1">
              <IconRound size="xsmall" align="center" @click="instagram" class="cursor">
                <logo-instagram-icon />
              </IconRound>
            </div>
            <div class="col-auto px-1">
              <IconRound size="xsmall" align="center" @click="youtube" class="cursor">
                <logo-youtube-icon />
              </IconRound>
            </div>
          </div>
        </div>
        <div class="col-12 ps-5 my-3">
          Follow Us. Stay Updated.
        </div>
      </div>
    </div>
    <div class="col-3 p-0" @click="closeNav">
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconRound: defineAsyncComponent(() => import('@/components/IconRound.vue')),
    // IconBank: defineAsyncComponent(() => import('@/components/icons/IconBank.vue')),
    IconPhone: defineAsyncComponent(() => import('@/components/icons/IconPhone.vue')),
    MenuItems: defineAsyncComponent(() => import('../components/MenuItems.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue')),
    'logo-facebook-icon': defineAsyncComponent(() => import('vue-ionicons/dist/logo-facebook.vue')),
    'logo-twitter-icon': defineAsyncComponent(() => import('vue-ionicons/dist/logo-twitter.vue')),
    'logo-instagram-icon': defineAsyncComponent(() => import('vue-ionicons/dist/logo-instagram.vue')),
    'logo-youtube-icon': defineAsyncComponent(() => import('vue-ionicons/dist/logo-youtube.vue'))
  },
  name: 'MobileMenu',
  data () {
    return {
      logo: process.env.VUE_APP_LOGO
    }
  },
  computed: {
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    goTo (val) {
      window.scrollTo(0, 0)
      this.$router.push('/' + val)
    },
    call (val) {
      window.location.href = 'tel:' + val
    },
    closeNav () {
      this.$emit('closeMenu')
    },
    facebook () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        window.open('https://en-gb.facebook.com/AlImdaadFoundationUK/', '_blank')
      } else {
        window.open('https://web.facebook.com/alimdaadhq', '_blank')
      }
    },
    twitter () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        window.open('https://twitter.com/Alimdaad_UK', '_blank')
      } else {
        window.open('https://twitter.com/Alimdaad', '_blank')
      }
    },
    instagram () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        window.open('https://www.instagram.com/alimdaadfoundationuk/', '_blank')
      } else {
        window.open('https://www.instagram.com/alimdaad/', '_blank')
      }
    },
    youtube () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        window.open('https://www.youtube.com/user/ALIMDAAD', '_blank')
      } else {
        window.open('https://www.youtube.com/user/ALIMDAAD', '_blank')
      }
    }
  }
}
</script>

<style scoped>
.above_all {
  z-index: 5;
  position: relative;
}
.ninety_degree {
  transform: rotate(90deg);
}
.menu_icon {
  background-color: transparent;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  font-size: 28px;
  color: var(--gold-color);
  text-align: center;
  padding-top: 12px;
}
.mobile_menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 4;
  color: #fff;
}
.menu_shadow {
  box-shadow: 75px 0px 75px 53px rgb(0 0 0 / 50%);
}
.slide-enter-active {
  transition: all 0.5s ease;
}
.slide-leave-active {
  transition: all 0.5s ease;
}
.slide-enter-from {
  transform: translateX(-100%);
  opacity: 1;
}
.slide-leave-to {
  transform: translateX(-100%);
  opacity: 1;
}
.menu_section {
  background-color: transparent;
  background-image: linear-gradient(180deg, #0D351C 0%, #1A6836 100%);
  height: 100%;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  z-index: 0;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;

}
.bg_overlay {
  background-image: url(../../../assets/images/p.png);
  /* background-size: 181px auto; */
  opacity: 0.05;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
</style>
